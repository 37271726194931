import { List } from "react-virtualized";
import { SearchResults } from "../../models/search";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { clsx } from "clsx";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { useState } from "react";

export interface TreeFilterNode {
  key: string;
  column: keyof SearchResults;
  label: string;
  value: string;

  selected: boolean;
  applied: boolean;
  indeterminate: boolean;
  allow_select?: boolean;

  children?: TreeFilterNode[];
  open: boolean;
}

export type TreeSelectNodeProps = {
  model: TreeFilterNode;
  handleFilterStateChange: (column: keyof SearchResults, filterVal: string, isSelected: boolean) => void;
  tier?: number;

  handleToggleOpenChildren?: (node: TreeFilterNode) => void;
  isOpen?: (node: TreeFilterNode) => boolean;

  filterChildrenByString?: string;
  disabled?: boolean;
};

const TreeSelectNode = ({
  model,
  handleFilterStateChange,
  tier,
  handleToggleOpenChildren = () => {},
  isOpen = () => false,
  filterChildrenByString,
  disabled = false,
}: TreeSelectNodeProps) => {
  const hasChildren = model?.children?.length ?? 0 > 0;
  const current_tier = tier || 1;

  const open = hasChildren ? isOpen(model) : false;

  // Post filter child list, also used to determine if we should show the parent node
  const children_nodes = (model.children || []).filter((_child) =>
    // Apply a case insensitive filter if a search string is provided
    filterChildrenByString
      ? _child.label.toLocaleLowerCase().includes(filterChildrenByString.toLocaleLowerCase())
      : true,
  );

  const parent_node = (
    <Box className={clsx(["TreeSelect-Node", `TreeSelect-Tier-${tier}`])} key={current_tier}>
      {/* Expansion Toggle  */}
      {hasChildren ? (
        <Box
          onClick={() => handleToggleOpenChildren(model)}
          sx={{ cursor: "pointer", "& .MuiSvgIcon-root": { verticalAlign: "middle" } }}
        >
          {open ? <ArrowDropDown /> : <ArrowRight />}
        </Box>
      ) : (
        <Box sx={{ ".TreeSelect-Tier-1 > &": { height: "28px", width: "24px" } }}></Box>
      )}

      {/* Filter Selection Input */}
      <FormControlLabel
        control={
          model.allow_select !== false ? (
            <Checkbox
              name={`${model.column}-${model.value}`}
              size="small"
              checked={model.selected}
              onChange={(e, checked) => handleFilterStateChange(model.column, model.value, !checked)}
              indeterminate={model.indeterminate}
              disabled={disabled}
            />
          ) : (
            <></>
          )
        }
        label={<span title={model.label}>{model.label}</span>}
        labelPlacement="end"
        sx={model.allow_select !== false ? {} : { paddingLeft: "14px", cursor: "default" }}
      />
    </Box>
  );

  const parentLabelIncludedInString = filterChildrenByString
    ? model.label.toLocaleLowerCase().includes(filterChildrenByString.toLocaleLowerCase())
    : true;

  const showParentNode = children_nodes.length > 0 || parentLabelIncludedInString;

  return [
    ...(showParentNode ? [parent_node] : []),
    ...(open
      ? children_nodes.map((childNodeModel, i) => (
          <TreeSelectNode
            key={`${current_tier}-${i}`}
            model={childNodeModel}
            tier={current_tier + 1}
            handleFilterStateChange={handleFilterStateChange}
            handleToggleOpenChildren={handleToggleOpenChildren}
            isOpen={isOpen}
            disabled={disabled}
          />
        ))
      : []),
  ];
};

export interface TreeSelectFilterVirtualProps {
  height: number;
  model: TreeFilterNode[];
  filterChildrenByString?: string;
  handleFilterStateChange: (column: keyof SearchResults, filterVal: string, isSelected: boolean) => void;
  disabled?: boolean;
}

export default function TreeSelectFilterVirtual({
  height,
  model,
  handleFilterStateChange,
  filterChildrenByString,
  disabled,
}: TreeSelectFilterVirtualProps) {
  const [treeState, setTreeState] = useState(new Map<string, boolean>());

  const isOpen = (node: TreeFilterNode) => {
    return treeState.get(node.value) ?? false;
  };

  const handleToggleOpenChildren = (node: TreeFilterNode) => {
    const key = node.value;
    setTreeState((_state) => {
      const newState = new Map(_state.entries());
      newState.set(key, !(newState.get(key) ?? false));
      return newState;
    });
  };

  // Flattened list for virtualized rendering
  const list = model.flatMap((node) => {
    return TreeSelectNode({
      handleFilterStateChange,
      model: node,
      handleToggleOpenChildren,
      isOpen,
      filterChildrenByString,
      disabled,
    });
  });

  const rowRenderer = ({ key, index, style }: RowRenderProps) => {
    return (
      <div key={key} style={style}>
        {list[index]}
      </div>
    );
  };

  const count = list.length;

  return (
    <>
      <Box
        sx={{
          "& .MuiFormControlLabel-root": {
            display: "block",
            margin: 0,
          },
          "& .MuiCheckbox-root": {
            padding: "4px",
            marginRight: "4px",
          },
          "& .TreeSelect-Node": {
            whiteSpace: "nowrap",
            display: "flex",
            flexDirection: "row",
          },
          "& .TreeSelect-Tier-1": { marginLeft: `${3.125 * 0}em` },
          "& .TreeSelect-Tier-2": { marginLeft: `${3.125 * 1}em` },
          "& .TreeSelect-Tier-3": { marginLeft: `${3.125 * 2}em` },
        }}
      >
        <List
          width={288}
          height={Math.min(height, 28 * count)}
          rowCount={count}
          rowHeight={28}
          rowRenderer={rowRenderer}
        />
      </Box>
    </>
  );
}

interface RowRenderProps {
  key: React.Key; // Unique key within array of rows
  index: number; // Index of row within collection
  isScrolling: boolean; // The List is currently being scrolled
  isVisible: boolean; // This row is visible within the List (eg it is not an overscanned row)
  style: React.CSSProperties; // Style object to be applied to row (to position it)
}
