import { AuthorizationPostResponse } from "../../models/auth";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

export interface UserState {
  token?: string;
  assignedRoles: string[];
  selectedRoles: string[];
  hasMultipleDomains: boolean;
  isLoading: boolean;
  intent?: string;
  urlRedirect?: string;
}

export const AuthorizationPostResponseAtom = atomWithStorage<AuthorizationPostResponse | undefined>(
  "Atom:AuthorizationPostResponse",
  undefined,
);

export const DefaultUserState: UserState = {
  assignedRoles: [],
  selectedRoles: [],
  hasMultipleDomains: false,
  isLoading: true,
};

export const UserStateAtom = atomWithStorage<UserState>("Atom:UserState", DefaultUserState, createJSONStorage(), {
  getOnInit: true,
});
