import { QuestionAnswerOutlined, AutoAwesomeOutlined } from "@mui/icons-material";
import { Box, Typography, Button } from "@mui/material";
import { WebSocketChatResponse } from "../../contexts/useDeepchat";
import { UserQuestion } from "./SalesDataTypes";
import { Interaction } from "./SalesDataState";

export default function SalesDataSessionOutline({
  interactions,
  onClickNewThread,
}: {
  interactions: Interaction[];
  onClickNewThread: () => void;
}) {
  return (
    <>
      <Box>
        <QuestionAnswerOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
        <Typography variant="h6" color={"#13294B"} display="inline-block">
          Current Thread
        </Typography>
      </Box>

      <Box
        sx={{
          overflowY: "scroll",
          pr: "2em",
          flex: 1,
          minHeight: 0,
          "& .thread-question": {
            marginBottom: "1em",
            color: "blue",
            cursor: "pointer",

            "&.focused": { borderLeft: "2px solid navy", color: "navy", pl: "0.5em" },
          },
        }}
      >
        {interactions.map((interaction, i) => {
          const response = interaction.messages.find((message) => message.type == "chat.initial");
          if (response) {
            const message = response as WebSocketChatResponse<UserQuestion>;
            const classes = ["thread-question", "focused"]; // hacking in "focused" state until this is a real outline

            // Ferreting away this code so that we can use it if/when we expand Sales Data to a conversation based interaction to match DeepChat's behaviour
            // if (focusedQuestion === response) {
            //   classes.push("focused");
            // }
            // const anchorId = `${String(message.data.user_session)}-${i}`;
            // <Box key={i} className={classes.join(" ")} onClick={() => scrollQuestionIntoView(anchorId)}>

            return (
              <Box key={i} className={classes.join(" ")}>
                {message.data.user_question}
              </Box>
            );
          } else return undefined;
        })}
      </Box>

      <Box sx={{ paddingRight: "2em", textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{ color: "#13294B" }}
          startIcon={<AutoAwesomeOutlined sx={{ fill: "#13294B" }} />}
          onClick={() => onClickNewThread()}
        >
          Start a New Question Thread
        </Button>
      </Box>
    </>
  );
}
