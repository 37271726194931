import dayjs from "dayjs";

export function hasDatePassed(date: string) {
  const startDate = dayjs(date, "YYYY-MM-DD");

  const now = dayjs();

  // Calculate the difference in days
  const diffInDays = startDate.diff(now, "day");

  // Return true if the difference is greater than or equal to the given number of days
  return diffInDays >= 0;
}
