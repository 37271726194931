import { AddBoxOutlined, Person, Settings, SvgIconComponent } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, styled } from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTopBarStore } from "../store/useTopBarStore";
import { MenuButtonType } from "../utils/models";
import { formatCustomTabName } from "./CustomDashboards/common";

const MenuButton = styled(Button)({
  color: "#13294B",
  fontWeight: 700,
  justifyContent: "center",
  // Make sure the icon stays colorized when we flip to variant="contained"
  "& .MuiSvgIcon-root path": {
    color: "#13294B",
  },
});

const MenuItem = ({ link, label, startIcon, sx }: MenuButtonType) => {
  const renderIcon = () => {
    if (React.isValidElement(startIcon)) {
      return startIcon;
    } else {
      const Icon = startIcon as SvgIconComponent;
      return <Icon />;
    }
  };
  // Check if the current path starts with the link
  const isActive = location.pathname.startsWith(link);

  return (
    <Link to={link}>
      <MenuButton variant={isActive ? "contained" : "text"} startIcon={renderIcon()}>
        {label}
      </MenuButton>
    </Link>
  );
};

export default function TopTabsMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const { menus, isMedOrCom, customTabs, isLoading } = useTopBarStore();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: "1em" }}>
      {menus.map((item, i) => {
        return <MenuItem key={i} {...item} />;
      })}

      {isMedOrCom &&
        customTabs.map((item, i) => (
          <MenuItem
            key={i}
            link={`/dashboards/custom/${i}`}
            label={formatCustomTabName(item.name)}
            startIcon={
              location.pathname.startsWith(`/dashboards/custom/${i}`) ? (
                <IconButton
                  component="div"
                  onClick={(e) => {
                    navigate(`/dashboards/custom/${i}/edit`);
                    e.preventDefault();
                  }}
                  sx={{ margin: "-0.5rem" }}
                >
                  <Settings fontSize="small" />
                </IconButton>
              ) : (
                Person
              )
            }
          />
        ))}

      {isMedOrCom && (
        <Box display="flex" sx={{ placeItems: "center" }}>
          {isLoading && <CircularProgress size="small" sx={{ gridArea: "1/1", width: "1.25rem", height: "1.25rem" }} />}
          {customTabs.length < 3 && (
            <Link
              to="/dashboards/custom/new"
              state={{ prevTab: location.pathname }}
              style={{ pointerEvents: isLoading ? "none" : undefined, gridArea: "1/1" }}
            >
              <MenuButton
                sx={{ height: "100%", minWidth: "0" }}
                variant={location.pathname === "/dashboards/custom/new" ? "contained" : "text"}
              >
                <AddBoxOutlined sx={{ gridArea: "1/1", opacity: isLoading ? 0.2 : 1 }} />
              </MenuButton>
            </Link>
          )}
        </Box>
      )}
    </Box>
  );
}
