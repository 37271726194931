import { ArticleOutlined, ExpandLess, ExpandMore } from "@mui/icons-material";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { Box, Button, Chip, Collapse, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { sentimentColorMap } from "../../cards/VerbatimsCard/VerbatimCard";
import { SourceDocument } from "../models";

export interface SupportingDocumentsProps {
  sources: SourceDocument[];
}

// Expandable Text Component
export const ExpandableText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box>
      <Typography
        fontSize={14}
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitLineClamp: expanded ? "none" : 2,
          transition: "all 0.3s",
        }}
      >
        {text}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          height: "40px",
          overflow: "hidden",
        }}
      >
        <Button
          size="small"
          onClick={() => setExpanded((prev) => !prev)}
          sx={{
            fontSize: "10px",
            color: "primary.dark",
            marginTop: "8px",
            minWidth: 0,
            display: text.length > 70 ? "flex" : "none",
            alignItems: "center",
          }}
        >
          {expanded ? "Show less" : "Show more"}
        </Button>
      </Box>
    </Box>
  );
};

const SupportingDocumentsBase = ({ sources }: SupportingDocumentsProps) => {
  const [supportingDocumentsExpanded, setSupportingDocumentsExpanded] = useState(false);

  return (
    <>
      <Box mb="1em">
        <ArticleOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
        <Typography variant="h6" color={"#13294B"} display="inline-block">
          Supporting Documents
        </Typography>
        <Box
          sx={{ display: "inline-block", verticalAlign: "middle", marginLeft: "0.5em", cursor: "pointer" }}
          onClick={() => setSupportingDocumentsExpanded((last) => !last)}
        >
          {supportingDocumentsExpanded ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>
      <Box>
        <Collapse in={supportingDocumentsExpanded}>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2, 1200: 3, 2000: 4 }}>
            <Masonry gutter="15px">
              {(sources ?? []).map((record, i) => {
                return (
                  <Box key={i}>
                    <Paper elevation={1} sx={{ borderRadius: "16px", overflow: "hidden" }}>
                      <Box sx={{ p: "0px", position: "relative" }}>
                        <Chip
                          label={record.sentiment || "unknown"}
                          sx={{
                            backgroundColor: record.sentiment
                              ? sentimentColorMap[record.sentiment.toLowerCase()]
                              : "rgba(255, 255, 255, 0.5",
                            color: Object.keys(sentimentColorMap).includes(record.sentiment?.toLowerCase())
                              ? "white"
                              : "primary.dark",
                            borderRadius: "0 0 0 8px",
                            textTransform: "uppercase",
                            letterSpacing: "0.05em",
                            lineHeight: 1,
                            width: "100px",
                            height: "24px",
                            position: "absolute",
                            right: "0",
                          }}
                        />
                        <Box
                          sx={{
                            px: "20px",
                            pt: "30px",
                            pb: "20px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontWeight={600} sx={{ lineHeight: 1, paddingBottom: "4px", height: "40px" }}>
                            {record.data_source}
                          </Typography>
                          <Typography fontSize={14} sx={{ color: "text.disabled" }}>
                            {dayjs(record.source_filter_date).format("YYYY-MM-DD")}
                          </Typography>
                          <Box
                            sx={{
                              mt: "2px",
                              backgroundColor: "primary.light",
                              fontSize: "11px",
                              fontWeight: "600",
                              padding: "10px",
                              display: "flex",
                              borderRadius: "8px",
                              flexDirection: "column",
                              width: "fit-content",
                              minWidth: "100px",
                              height: "70px",
                            }}
                          >
                            <Typography
                              sx={{ textOverflow: "ellipsis", WebkitLineClamp: 1, maxLines: 1 }}
                              fontSize={"10px"}
                            >
                              {record.topics || "No Topic"}
                            </Typography>
                            {record.subtopic && (
                              <Box sx={{ display: "flex", mt: "5px" }}>
                                <SubdirectoryArrowRightIcon sx={{ fontSize: "14px" }} />
                                <Typography fontSize={"10px"} sx={{ textOverflow: "ellipsis" }}>
                                  {record.subtopic}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                          <Box mt="1em" sx={{ flexGrow: 1 }}>
                            <Typography fontSize={12} sx={{ color: "text.disabled" }}>
                              Verbatim
                            </Typography>
                            <ExpandableText text={record.text}></ExpandableText>
                          </Box>
                        </Box>

                        {/* Hiding this component for 2477 until we get more information on how we want this to be handled - Eric */}
                        {/* <VerbatimCardFeedback record={record} record_id={record.join_id} /> */}
                      </Box>
                    </Paper>
                  </Box>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </Collapse>
      </Box>
    </>
  );
};

export default React.memo(
  SupportingDocumentsBase,
  (prevProps: Readonly<SupportingDocumentsProps>, nextProps: Readonly<SupportingDocumentsProps>) => {
    return prevProps.sources === nextProps.sources;
  },
);
