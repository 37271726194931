import { atom } from "jotai";
import { ChatResponse } from "../models";
import { WebSocketHook } from "react-use-websocket/dist/lib/types";
import { WebSocketChatResponse } from "../../../contexts/useDeepchat";

export const QuestionTextAtom = atom<string>("");

export const QuestionIsLoadingAtom = atom<boolean>(false);

export const QuestionTargetSalesData = atom<boolean>(false);

// This is to prevent user feedback from being submitted before the server has saved a session, which would result in an error
export const QuestionCanAcceptFeedback = atom<boolean>(false);

export const SessionThreadAtom = atom<ChatResponse[]>([]);

export const FocusedThreadQuestion = atom<ChatResponse | undefined>(undefined);

export const DeepchatSocketAtom = atom<
  WebSocketHook<WebSocketChatResponse<unknown>, MessageEvent<unknown> | null> | undefined
>(undefined);

export interface QuestionEnhancerState {
  loading: boolean;
  open: boolean;
  starting_query?: string;
  options: string[];
  selected_option?: number;
}

export const DefaultQuestionEnhancerState: QuestionEnhancerState = {
  loading: false,
  open: false,
  starting_query: undefined,
  options: [],
  selected_option: undefined,
};

export const QuestionEnhancerAtom = atom<QuestionEnhancerState>(DefaultQuestionEnhancerState);
