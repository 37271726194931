import { Box, Button, Chip, Collapse, Paper, Typography } from "@mui/material";
import { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { PMREvidencesProps, TQAChatEvidence } from "./models";
import { formatResponseText } from "./ViewTransript/ViewTranscript";

const HeaderAndValue = ({ header, value }) => {
  return (
    <>
      <Typography variant="h6" sx={{ fontSize: "10px", color: "rgba(66, 84, 102, 0.6)", marginTop: "20px" }}>
        {header}
      </Typography>
      <Typography fontSize={14}>{formatResponseText(value, "13px", "13px")}</Typography>
    </>
  );
};

const ExpandableText = ({
  text,
  evidence,
  confidence_details,
  comprehensive_notes,
  expanded,
  toggleExpand,
}: TQAChatEvidence) => {
  return (
    <Box>
      <Collapse collapsedSize={40} in={expanded}>
        <Typography fontSize={14}>{formatResponseText(text, "13px", "13px")}</Typography>
        <HeaderAndValue header={"EVIDENCE"} value={evidence}></HeaderAndValue>
        <HeaderAndValue header={"CONFIDENCE DETAILS"} value={confidence_details}></HeaderAndValue>
        <HeaderAndValue header={"COMPREHENSIVE NOTES"} value={comprehensive_notes}></HeaderAndValue>
      </Collapse>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          height: "40px",
          overflow: "hidden",
        }}
      >
        <Button
          size="small"
          onClick={(e) => {
            toggleExpand(e);
          }}
          sx={{
            fontSize: "10px",
            color: "primary.dark",
            marginTop: "8px",
            minWidth: 0,
            display: text.length > 50 ? "flex" : "none",
            alignItems: "center",
          }}
        >
          {expanded ? "Show less" : "Show more"}
        </Button>
      </Box>
    </Box>
  );
};

export default function PMREvidences({ evidences }: PMREvidencesProps) {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  // Handler to toggle expansion:
  const handleCardClick = (index: number) => {
    setExpandedIndex((prev) => (prev === index ? null : index));
  };

  return (
    <Box sx={{ py: "1rem" }}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2, 1200: 2, 2000: 3 }}>
        <Masonry gutter="15px">
          {evidences?.map((record, i) => {
            const isExpanded = expandedIndex === i;
            return (
              <Paper key={i} elevation={1} sx={{ borderRadius: "16px", overflow: "hidden" }}>
                <Box sx={{ p: "0px", position: "relative", cursor: "pointer" }} onClick={() => handleCardClick(i)}>
                  <Chip
                    label={`CONFIDENCE:  ${record.confidence_score}`}
                    sx={{
                      borderRadius: "0 0 0 8px",
                      textTransform: "uppercase",
                      letterSpacing: "0.05em",
                      lineHeight: 1,
                      width: "100px",
                      height: "24px",
                      position: "absolute",
                      right: "0",
                      backgroundColor: "#8DC8E8",
                      color: "#fff",
                      fontSize: "10px",
                    }}
                  />
                  <Box sx={{ px: "10px", pt: "20px" }}>
                    <Typography variant="h6" sx={{ fontSize: "10px", color: "rgba(66, 84, 102, 0.6)" }}>
                      QUESTION
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "12px", color: "rgba(37, 40, 42, 1)" }}>
                      {record.question}
                    </Typography>

                    <Typography
                      variant="h6"
                      sx={{ fontSize: "10px", color: "rgba(66, 84, 102, 0.6)", marginTop: "10px" }}
                    >
                      ANSWER
                    </Typography>
                    <ExpandableText
                      text={record.complete_answer ?? ""}
                      evidence={record.exhaustive_supporting_evidence}
                      confidence_details={record.detailed_confidence ?? ""}
                      comprehensive_notes={record.comprehensive_notes ?? ""}
                      expanded={isExpanded}
                      toggleExpand={(e) => {
                        e.stopPropagation();
                        handleCardClick(i);
                      }}
                    />
                  </Box>
                </Box>
              </Paper>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </Box>
  );
}
