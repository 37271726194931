import { Immunology, Oncology, Other } from "../components/Deepchat/AskQuestion/CardIcons";

export const commercialQuestions = [
  {
    text: "What is being said about TV Commercials, Advertisements, AND/OR other Marketing Communications?",
    Icon: Other,
  },
  { text: "What are patients and customers saying about TV Commercials and Advertisements?", Icon: Other },
  {
    text: "How can customer experience with TV Commercials, Advertisements, and Marketing Communications be improved? Provide clear rationale for your recommendations.",
    Icon: Other,
  },
  {
    text: "Can you summarize the feedback regarding the Actmera ACT Pen injector? Make sure to capture all the key points.",
    Icon: Other,
  },
  {
    text: "What are the most important themes and sentiments related to the Actemra ACT Pen injector? Only use context where Actemra ACT Pen injector is explicitly mentioned when responding.",
    Icon: Other,
  },
  { text: "What are customers saying about problems with insurance and medication access?", Icon: Other },
  {
    text: "What are customers saying about problems related to insurance, billing support, and financial cost of medications?",
    Icon: Other,
  },
  {
    text: "What are the main themes and criticisms related to product access, medication billing, and reimbursement?",
    Icon: Other,
  },
  {
    text: "What are some actions Genentech can take to improve customer satisfaction and experience? Provide clear rationale for your recommendations.",
    Icon: Other,
  },
];

export const medicalQuestions = [
  {
    text: "Can you summarize the feedback regarding Xolair for Food Allergy? Make sure to capture all the key points.",
    Icon: Immunology,
  },
  {
    text: "Can you summarize the feedback regarding the Subcutaneous Tecentriq? Make sure to capture all the key points.",
    Icon: Oncology,
  },
  {
    text: "What are the most important themes and sentiments related to the stability of Ocrevus SubQ? Only use context where Ocrevus is explicitly mentioned when responding.",
    Icon: Other,
  },
  {
    text: "How can patient experience related to Health Equity be improved? Provide clear rationale for your recommendations.",
    Icon: Other,
  },
];

export const salesDataQuestions = [
  {
    text: "What're the top drug sales by units in Birmingham last year?",
    Icon: Other,
  },
  {
    text: "Give me the top accounts with the highest Ocrevus sales in q1 2024",
    Icon: Other,
  },
  {
    text: "Which products had the largest growth at Johns Hopkins from q4 23 to q1 24?",
    Icon: Other,
  },
];
