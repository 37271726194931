import { atom } from "jotai";
import { WebSocketChatResponse } from "../../contexts/useDeepchat";

export const SalesDataStateAtom = atom<SalesDataState>({
  loading: false,
  interactions: [],
});

export interface SalesDataState {
  loading: boolean;
  interactions: Interaction[];
}

export interface Interaction {
  messages: WebSocketChatResponse<unknown>[];
}
