import axios, { AxiosResponse } from "axios";
import { UserState } from "../components/Authorization/AuthorizationState";
import { TGetSignedS3UrlResponse, TRecentDocumentsHistory } from "../components/Deepchat/models";
import {
  MarketResearchStudy,
  StudyQuestion,
  TMRTranscripts,
  TPMRChat,
  TStudySummary,
} from "../components/MarketResearch/models";
import {
  ApiResponse,
  DataDistribution,
  DocumentUsageResponse,
  EcosystemResponse,
  EmergingTopicsResponse,
  InquiryVolumeBySourceResponse,
  InquiryVolumeOverTimeResponse,
  KeywordsResponse,
  RegionResponse,
  SentimentData,
  TopicDistribution,
  TopicsNoInfoInquiriesResponse,
} from "../models/datasource";
import { FilterResponse } from "../models/filter";
import { SearchFilters } from "../models/search";
import { buildHeaders } from "./utils";

const API_URI = import.meta.env.VITE_APP_API_URI;

// type just for the response data, not used outside of the API request
type DataDistributionResponse = {
  data: DataDistribution[];
};

export const getDataDistributions = async (filters: SearchFilters, user: UserState): Promise<DataDistribution[]> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<DataDistributionResponse> = await axios.post(
    `${API_URI}/search/distributions`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data.data;
};

export const getTopicDistribution = async (
  filters: SearchFilters,
  user: UserState,
): Promise<TopicDistribution<unknown>> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<TopicDistribution<unknown>> = await axios.post(
    `${API_URI}/search/topics-sentiments`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getKeywords = async (filters: SearchFilters, user: UserState): Promise<KeywordsResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<KeywordsResponse> = await axios.post(`${API_URI}/search/keywords`, filters, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const getEmergingTopics = async (filters: SearchFilters, user: UserState): Promise<EmergingTopicsResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set");

  const response: AxiosResponse<EmergingTopicsResponse> = await axios.post(
    `${API_URI}/search/emerging-topics`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getEcosystems = async (filters: SearchFilters, user: UserState): Promise<EcosystemResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<EcosystemResponse> = await axios.post(
    `${API_URI}/search/ecosystem-distribution`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getRegions = async (filters: SearchFilters, user: UserState): Promise<RegionResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<RegionResponse> = await axios.post(`${API_URI}/search/region-distribution`, filters, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const getSentiment = async (filters: SearchFilters, user: UserState): Promise<ApiResponse<SentimentData>> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<ApiResponse<SentimentData>> = await axios.post(
    `${API_URI}/search/time-series-sentiments`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getDocumentUsage = async (filters: SearchFilters, user: UserState): Promise<DocumentUsageResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<DocumentUsageResponse> = await axios.post(`${API_URI}/search/products`, filters, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const getInquiryVolumeBySource = async (
  filters: SearchFilters,
  user: UserState,
): Promise<InquiryVolumeBySourceResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<InquiryVolumeBySourceResponse> = await axios.post(
    `${API_URI}/search/time-series-inquiry-source-volume`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getInquiryVolumeOverTime = async (
  filters: SearchFilters,
  user: UserState,
): Promise<InquiryVolumeOverTimeResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<InquiryVolumeOverTimeResponse> = await axios.post(
    `${API_URI}/search/time-series-inquiry-customer-volume`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getTopicsNoInfoInquiries = async (
  filters: SearchFilters,
  user: UserState,
): Promise<TopicsNoInfoInquiriesResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<TopicsNoInfoInquiriesResponse> = await axios.post(
    `${API_URI}/search/topics-no-information`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getPreSignedS3Url = async (user: UserState, fileName: string): Promise<TGetSignedS3UrlResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");
  const response: AxiosResponse<TGetSignedS3UrlResponse> = await axios.put(
    `${API_URI}/byod/request_document_upload?original_document_name=${fileName}`,
    "",
    {
      headers: buildHeaders(user),
    },
  );
  if (response.status !== 200) {
    throw new Error("Failed to get recent documents history");
  }

  return response.data;
};

export const getRecentDocumentsHistory = async (user: UserState): Promise<any> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<TRecentDocumentsHistory[]> = await axios.get(`${API_URI}/byod/history`, {
    headers: buildHeaders(user),
  });

  if (response.status !== 200) {
    throw new Error("Failed to get recent documents history");
  }

  return response.data;
};

export const uploadDocumentToS3 = async (url: string, file: File): Promise<boolean> => {
  if (!API_URI) throw new Error("API URI env variable not set.");
  console.log("file", file.name);

  const response: AxiosResponse = await axios.put(`${url}`, file, {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });

  if (response.status !== 200) {
    return false;
  }

  return true;
};

export const callStartSummarizeDocAPI = async (user: UserState, json: Record<string, unknown>): Promise<any> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse = await axios.post(`${API_URI}/byod/start_processing`, json, {
    headers: buildHeaders(user),
  });

  if (response.status !== 200) {
    throw new Error("Failed to get recent documents history");
  }

  return response.data;
};

export const callGetAllStudies = async (user: UserState): Promise<MarketResearchStudy[]> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse = await axios.get(`${API_URI}/pmr/studies`, {
    headers: buildHeaders(user),
  });

  if (response.status !== 200) {
    throw new Error("Failed to get recent documents history");
  }

  return response.data;
};

export const callGetMRQuestions = async (
  user: UserState,
  study_name: string,
  search: Record<string, unknown>,
): Promise<StudyQuestion[]> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse = await axios.post(`${API_URI}/pmr/study/${study_name}`, search, {
    headers: buildHeaders(user),
  });

  if (response.status !== 200) {
    throw new Error("Failed to get recent documents history");
  }

  return response.data;
};

export const callMRFilters = async (user: UserState, search: Record<string, any>): Promise<FilterResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse = await axios.post<FilterResponse>(`${API_URI}/filters/facets`, search, {
    headers: buildHeaders(user),
  });

  if (response.status !== 200) {
    throw new Error("Failed to get recent documents history");
  }

  return response.data;
};

export const callMRViewTranscripts = async (
  user: UserState,
  studyId: string,
  respondentId: string,
): Promise<TMRTranscripts> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse = await axios.get<TMRTranscripts>(
    `${API_URI}/pmr/study/${studyId}/transcript/${respondentId}`,
    {
      headers: buildHeaders(user),
    },
  );

  if (response.status !== 200) {
    throw new Error("Failed to get recent documents history");
  }

  return response.data;
};

export const callPMRStudySummary = async (user: UserState, studyId: string): Promise<TStudySummary> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse = await axios.get(`${API_URI}/pmr/study/${studyId}/summary`, {
    headers: buildHeaders(user),
  });

  if (response.status !== 200) {
    throw new Error("Failed to get recent documents history");
  }

  return response.data;
};

export const callGetQuestionSummary = async (user: UserState, questionId: string): Promise<TPMRChat> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse = await axios.post(
    `${API_URI}/pmr/question/${questionId}`,
    {},
    {
      headers: buildHeaders(user),
    },
  );

  if (response.status !== 200) {
    throw new Error("Failed to get recent documents history");
  }

  return response.data;
};
