import { useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import * as DeepchatApi from "../../api/deepchat";
import useUserContext from "../../components/Authorization/useUserContext";
import { WebSocketChatResponse } from "../../contexts/useDeepchat";
import { SalesDataState, SalesDataStateAtom } from "./SalesDataState";

const DEEPCHAT_WS_URI = import.meta.env.VITE_APP_DEEPCHAT_WS_URI;

export const useSalesDataStore = () => {
  const user = useUserContext();

  // Atoms
  const [, setState] = useAtom(SalesDataStateAtom);

  const formatChatUrl = (chatAuthCode: string) => {
    return `${DEEPCHAT_WS_URI}?authCode=${chatAuthCode}`;
  };

  // WebSocket
  const getChartUrl = useCallback(async () => {
    const response = await DeepchatApi.getToken(user);
    return formatChatUrl(response.auth_code);
  }, []);

  const getWebSocket = useCallback(() => useWebSocket<WebSocketChatResponse<unknown>>(getChartUrl), [0]);
  const { lastJsonMessage, sendJsonMessage, readyState } = getWebSocket();

  // Collect messages from the open WebSocket
  useEffect(() => {
    // This logic is different than how DeepChat works because in DeepChat we have a "updating" Answer message
    // that we have to continually sync with the latest message, whereas here in "Structured Data" every message is atomic
    const type = lastJsonMessage?.type;
    if (type) {
      setState((_) => {
        const next = { ..._ } as SalesDataState;

        if (type == "chat.initial") {
          // If we are handling a new interaction, add a new interaction to the stack with the current message
          next.interactions = [...next.interactions, { messages: [lastJsonMessage] }];
        } else {
          // Otherwise append the incoming message to the last interaction
          next.interactions = [...next.interactions];
          const lastInteraction = next.interactions.at(-1);

          if (lastInteraction) {
            lastInteraction.messages = [...lastInteraction.messages, lastJsonMessage];
          }
        }

        if (type == "chat.complete") {
          next.loading = false;
        }

        return next;
      });
    }
  }, [lastJsonMessage]);

  const clearSession = () => {
    setState((_) => ({ interactions: [], loading: false }));
  };

  const setLoading = (loading: boolean) => {
    setState((_) => ({ ..._, loading }));
  };

  return {
    lastJsonMessage,
    sendJsonMessage,
    readyState,

    clearSession,
    setLoading,
  };
};

const SocketConnectionStatus = {
  [ReadyState.CONNECTING]: "Connecting",
  [ReadyState.OPEN]: "Open",
  [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  [ReadyState.CLOSING]: "Closing",
  [ReadyState.CLOSED]: "Closed",
};
