import { Box, Button, Input, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useDeepchat from "../../contexts/useDeepchat";
import { useDeepChatSocketContext } from "../../contexts/DeepChatSocketContext";
import { useSalesDataStore } from "./useSalesDataStore";
import { useSearchParams } from "react-router-dom";
import { ReadyState } from "react-use-websocket";

export default function SalesDataAsk() {
  const [searchParams] = useSearchParams();

  const SalesData = useSalesDataStore();
  const Deepchat = useDeepchat();
  const DeepChatSocket = useDeepChatSocketContext();

  const [input, setInput] = useState("");

  // We only want to apply a replace if user is coming in from Deepchat page
  const submitSearch = async (question: string, replace: boolean) => {
    SalesData.clearSession();
    SalesData.setLoading(true);
    Deepchat.askSalesDataStreaming(DeepChatSocket.sendJsonMessage, question, replace);
  };

  const userQuestion = searchParams.get("question");
  useEffect(() => {
    if (DeepChatSocket.readyState == ReadyState.OPEN && userQuestion) {
      submitSearch(userQuestion, true);
    }
  }, [searchParams, DeepChatSocket.readyState]);

  // Top 5 growth accounts for last quarter for Ocrevus?
  // What're the top 5 growing product molecule Ocrevus accounts of q3 vs q2?
  // What was ocrevus's market share in july of last year?
  // How many more units of tnkase did we sell last quarter vs the one before?

  // Block the default UI if a user is coming in from DeepChat UI
  if (userQuestion) {
    return <></>;
  }

  return (
    <>
      <Box flex={1} minHeight="100%" marginX="auto" maxWidth="48rem">
        <Stack
          className="QuestionInput"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ minHeight: "100%" }}
        >
          <Typography variant="h3" fontWeight="bold" color={"rgb(0, 48, 135)"}>
            Deepchat
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {/* <DeepchatNavigationTabs /> */}
            {/* <QuestionInput placeholder="Ask a related follow-up question…" hideEnhanceButton={true} /> */}
            <Input
              value={input}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInput(event.target.value);
              }}
            />
            <Button onClick={() => submitSearch(input, false)}>Go</Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
