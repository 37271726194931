import { ArrowForward, AutoFixHigh, FilterList } from "@mui/icons-material";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, InputBase, Paper, Tooltip } from "@mui/material";
import { useAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { useNavigate } from "react-router-dom";
import { grey } from "../../../colors";
import { useQuestionEnhancer } from "../../../store/home/useQuestionEnhancer";
import { useQuestionInputStore } from "../../../store/home/useQuestionInputStore";
import { QuestionTargetSalesData } from "../state/DeepchatState";
import QueryEnhancePanel from "./QueryEnhancePanel";

// Simple validation method to block the majority of basic nonsense being submitted to the query enhancer
export const isValidQuestion = (question: string) => {
  const text = String(question);
  const words = text.split(" ");
  return text.length > 8 && words.length > 2 && words.some((_word) => _word.length > 2);
};

export interface QuestionInputProps {
  placeholder?: string;
  hideEnhanceButton?: boolean;
  salesdata?: boolean;
}

export default function QuestionInput(
  { placeholder, hideEnhanceButton, salesdata }: QuestionInputProps = { hideEnhanceButton: false, salesdata: false },
) {
  const navigate = useNavigate();

  useHydrateAtoms([[QuestionTargetSalesData, salesdata ?? false]]);
  const [checkboxSalesData, setCheckboxSalesData] = useAtom(QuestionTargetSalesData);

  // Hide the the following controls when "Sales Data" is selected
  hideEnhanceButton = checkboxSalesData;
  const hideFilters = checkboxSalesData;

  const {
    disableFilters,
    loading,
    question,
    LeftCallout,
    RightCallout,
    QECallout,
    submitQuestion,
    orientation,
    setQuestion,
    setHideFilterDrawer,
    hideFilterDrawer,
  } = useQuestionInputStore();

  const { enhanceQuestion, closeEnhancerPanel, resetEnhancerState, QE } = useQuestionEnhancer();

  const handleSubmitQuestion = (question: string) => {
    if (!checkboxSalesData) {
      closeEnhancerPanel();
      resetEnhancerState();

      submitQuestion(question);
    } else {
      navigate(`/salesdata/?question=${question}`);
    }
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        width: "100%",
        flexDirection: disableFilters ? "row" : "column",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, p: disableFilters ? "" : "0.5rem", mt: 1 }}
        disabled={loading}
        multiline
        placeholder={placeholder || "Select filters to narrow down the data, then ask a question..."}
        value={question}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            // Block page from reloading on "Enter"
            event.preventDefault();
            handleSubmitQuestion(question);
          }
        }}
        onChange={(event) => {
          setQuestion(event.target.value);
        }}
      />
      <QueryEnhancePanel sx={{ display: QE.open ? "block" : "none" }} onSelectOption={setQuestion} />
      <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft="1rem">
        {!disableFilters && !hideFilters && (
          <LeftCallout placement={{ top: "100%", left: "100%" }} x="left" y="top" axis="y">
            <Button onClick={() => setHideFilterDrawer(!hideFilterDrawer)} size="small" startIcon={<FilterList />}>
              Filter
            </Button>
          </LeftCallout>
        )}
        <Box sx={{ ml: "1em" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxSalesData}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCheckboxSalesData(event.target.checked)}
              />
            }
            label="Sales Data"
          />
        </Box>
        <Box sx={{ flex: 1 }}></Box>
        {loading ? (
          <CircularProgress sx={{ p: "0.25em", m: "4px" }} />
        ) : (
          <Box display="flex">
            {!hideEnhanceButton && (
              <Tooltip
                disableHoverListener={!question || isValidQuestion(question)}
                title="Please enter a valid question with 3 or more words"
              >
                <span>
                  <QECallout placement={{ bottom: "100%", left: "50%" }} x="left" y="bottom" axis="y">
                    <Button
                      disableElevation
                      disabled={!isValidQuestion(question)}
                      variant="contained"
                      aria-label="enhance"
                      sx={{ padding: "8px", margin: "4px", minWidth: "unset", color: grey[700] }}
                      onClick={() => enhanceQuestion(question)}
                    >
                      <AutoFixHigh />
                    </Button>
                  </QECallout>
                </span>
              </Tooltip>
            )}
            <RightCallout x={orientation.x} y={orientation.y} axis={orientation.axis} placement={orientation.placement}>
              <Button
                disableElevation
                disabled={!question}
                variant="contained"
                aria-label="search"
                sx={{ padding: "8px", margin: "4px", minWidth: "unset", color: grey[700] }}
                onClick={() => handleSubmitQuestion(question)}
              >
                <ArrowForward />
              </Button>
            </RightCallout>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
