import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { ActivityType, ChatResponse } from "../../components/Deepchat/models";
import { QuestionTargetSalesData } from "../../components/Deepchat/state/DeepchatState";
import useDeepchat from "../../contexts/useDeepchat";

//ATOMS
export const historyLoadingAtom = atom(true);
export const historyAtom = atom<ChatResponse[][]>([]);

export const useRecentThreads = () => {
  const Deepchat = useDeepchat();
  const [historyLoading, setHistoryLoading] = useAtom(historyLoadingAtom);
  const [history, setHistory] = useAtom(historyAtom);

  const [checkboxSalesData] = useAtom(QuestionTargetSalesData);

  const activity_type: ActivityType | undefined = checkboxSalesData ? "structured_data" : undefined;

  useEffect(() => {
    Deepchat.getHistory(activity_type).then((_history) => {
      setHistoryLoading(false);
      setHistory(_history);
    });
  }, []);

  return {
    historyLoading,
    history,
  };
};
