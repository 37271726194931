import { ChatOutlined, TextSnippetOutlined } from "@mui/icons-material";
import { Badge, Box, Button, Card, Chip, CircularProgress, Collapse, Paper, Typography } from "@mui/material";
import { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link } from "react-router-dom";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";
import { TQAChatEvidence } from "../models";
import useMarketResearchStore from "../useMarketResearchStore";
import { formatResponseText } from "../ViewTransript/ViewTranscript";

const HeaderAndValue = ({ header, value }) => {
  return (
    <>
      <Typography variant="h6" sx={{ fontSize: "10px", color: "rgba(66, 84, 102, 0.6)", marginTop: "20px" }}>
        {header}
      </Typography>
      <Typography fontSize={14}>{formatResponseText(value, "13px", "13px")}</Typography>
    </>
  );
};

// Expandable Text Component
const ExpandableText = ({
  text,
  evidence,
  confidence_details,
  comprehensive_notes,
  expanded,
  toggleExpand,
}: TQAChatEvidence) => {
  return (
    <Box>
      <Collapse collapsedSize={40} in={expanded}>
        <Typography fontSize={14}>{formatResponseText(text, "13px", "13px")}</Typography>
        <HeaderAndValue header={"EVIDENCE"} value={evidence}></HeaderAndValue>
        <HeaderAndValue header={"CONFIDENCE DETAILS"} value={confidence_details}></HeaderAndValue>
        <HeaderAndValue header={"COMPREHENSIVE NOTES"} value={comprehensive_notes}></HeaderAndValue>
      </Collapse>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          height: "40px",
          overflow: "hidden",
        }}
      >
        <Button
          size="small"
          onClick={(e) => {
            toggleExpand(e);
          }}
          sx={{
            fontSize: "10px",
            color: "primary.dark",
            marginTop: "8px",
            minWidth: 0,
            display: text.length > 50 ? "flex" : "none",
            alignItems: "center",
          }}
        >
          {expanded ? "Show less" : "Show more"}
        </Button>
      </Box>
    </Box>
  );
};

export default function QAChat() {
  const { studyId, getStudy, isQAChatLoading, qaChatResponse, questionResponse, navigate, setQuestionResponse } =
    useMarketResearchStore();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  // Get the study details
  const study = studyId ? getStudy(studyId) : undefined;

  // Handler to toggle expansion:
  const handleCardClick = (index: number) => {
    setExpandedIndex((prev) => (prev === index ? null : index));
  };

  return (
    <Box
      component="main"
      sx={{
        height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
        width: "100%",
        display: "flex",
        marginTop: `${TOP_BAR_HEIGHT}px`,
      }}
    >
      {/* LEFT COLUMN */}
      <Box
        sx={{
          // Either set a fixed width or use flex-basis:
          flex: "0 0 75%",
          display: "flex",
          flexDirection: "column",
          // Add right border if desired
          borderRight: "1px solid #eee",
          p: "24px",
          boxSizing: "border-box",
        }}
      >
        {/* HEADER (Non-Scrolling) */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexShrink: 0, // Don’t shrink the header
            mb: 2,
          }}
        >
          <Typography variant="h5" sx={{ color: "#004677" }}>
            {study?.study_title}
          </Typography>
          <Link to={"/market-research"} style={{ textDecoration: "none", color: "#003087" }}>
            CHANGE STUDY
          </Link>
        </Box>

        {/* SCROLLABLE SUMMARY AREA */}
        <Box
          sx={{
            // The magic for scrolling in a flex column:
            // 1) flex: 1 to take remaining space
            // 2) minHeight: 0 so the box can shrink to let content scroll
            flex: 1,
            minHeight: 0,
            overflowY: "auto",
            pb: "10px",
            // Gap or margin if needed
          }}
        >
          {/* "Deepsense Summary" heading */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              backgroundColor: "#EFF0F0",
              padding: "20px",
              gap: "20px",
            }}
          >
            <Badge
              badgeContent={questionResponse?.responses}
              color="secondary"
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  fontSize: "10px",
                },
              }}
            >
              <ChatOutlined fontSize="small" sx={{ color: "#003087", marginTop: "5px" }} />
            </Badge>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>
              {questionResponse?.question_title}
            </Typography>
          </Box>
          {/* Card that wraps the summary text */}
          <Card sx={{ mb: 2 }}>
            <Box sx={{ padding: "1rem" }}>
              {isQAChatLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <CircularProgress />
                </Box>
              ) : (
                formatResponseText(qaChatResponse?.summary ?? "")
              )}
            </Box>
          </Card>
          <Box sx={{ display: "flex", alignItems: "center", my: 3, gap: "10px" }}>
            <TextSnippetOutlined />
            <Typography variant="h6" color={"#13294B"} display="inline-block" sx={{ fontSize: "20px" }}>
              Relevant Text Records
            </Typography>
          </Box>
          <Box>
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2, 1200: 2, 2000: 3 }}>
              <Masonry gutter="15px">
                {qaChatResponse?.evidences?.map((record, i) => {
                  const isExpanded = expandedIndex === i;
                  return (
                    <Paper key={i} elevation={1} sx={{ borderRadius: "16px", overflow: "hidden" }}>
                      <Box
                        sx={{ p: "0px", position: "relative", cursor: "pointer" }}
                        onClick={() => handleCardClick(i)}
                      >
                        <Chip
                          label={`CONFIDENCE:  ${record.confidence}`}
                          sx={{
                            borderRadius: "0 0 0 8px",
                            textTransform: "uppercase",
                            letterSpacing: "0.05em",
                            lineHeight: 1,
                            width: "100px",
                            height: "24px",
                            position: "absolute",
                            right: "0",
                            backgroundColor: "#8DC8E8",
                            color: "#fff",
                            fontSize: "10px",
                          }}
                        />
                        <Box sx={{ px: "10px", pt: "20px" }}>
                          <Typography variant="h6" sx={{ fontSize: "10px", color: "rgba(66, 84, 102, 0.6)" }}>
                            QUESTION
                          </Typography>
                          <Typography variant="body1" sx={{ fontSize: "12px", color: "rgba(37, 40, 42, 1)" }}>
                            {questionResponse?.question_title}
                          </Typography>

                          <Typography
                            variant="h6"
                            sx={{ fontSize: "10px", color: "rgba(66, 84, 102, 0.6)", marginTop: "10px" }}
                          >
                            ANSWER
                          </Typography>
                          <ExpandableText
                            text={record.complete_answer ?? ""}
                            evidence={record.evidence ?? ""}
                            confidence_details={record.confidence_details ?? ""}
                            comprehensive_notes={record.comprehensive_notes ?? ""}
                            expanded={isExpanded}
                            toggleExpand={(e) => {
                              e.stopPropagation();
                              handleCardClick(i);
                            }}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </Box>
        </Box>
      </Box>

      {/* RIGHT COLUMN */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: "2rem",
          justifyContent: "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "#003087", width: "100%", display: "none", justifyContent: "center", alignItems: "center" }}
          >
            BACK TO DISCUSSION GUIDE
          </Button>

          <Button
            variant="outlined"
            sx={{ color: "#003087", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            BACK TO STUDY
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
