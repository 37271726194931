import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { AutoAwesomeOutlined } from "@mui/icons-material";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";
import useMarketResearchStore from "../useMarketResearchStore";
import InterviewComponent from "../ViewStudy/InterviewComponent";
import { formatResponseText } from "../ViewTransript/ViewTranscript";

export default function StudySummary() {
  const params = useParams();

  const { studyId, setStudyId, getStudy, isStudySummaryLoading, studySummaryResponse } = useMarketResearchStore();

  // On mount/update, sync the studyId
  useEffect(() => {
    if (params.studyId && params.studyId !== studyId) {
      setStudyId(params.studyId);
    }
  }, [params, studyId, setStudyId]);

  // Get the study details
  const study = studyId ? getStudy(studyId) : undefined;

  return (
    <Box
      component="main"
      sx={{
        height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
        width: "100%",
        display: "flex",
        marginTop: `${TOP_BAR_HEIGHT}px`,
      }}
    >
      {/* LEFT COLUMN */}
      <Box
        sx={{
          // Either set a fixed width or use flex-basis:
          flex: "0 0 75%",
          display: "flex",
          flexDirection: "column",
          // Add right border if desired
          borderRight: "1px solid #eee",
          p: "24px",
          boxSizing: "border-box",
        }}
      >
        {/* HEADER (Non-Scrolling) */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexShrink: 0, // Don’t shrink the header
            mb: 2,
          }}
        >
          <Typography variant="h5" sx={{ color: "#004677" }}>
            {study?.study_title}
          </Typography>
          <Link to={"/market-research"} style={{ textDecoration: "none", color: "#003087" }}>
            CHANGE STUDY
          </Link>
        </Box>

        {/* SCROLLABLE SUMMARY AREA */}
        <Box
          sx={{
            // The magic for scrolling in a flex column:
            // 1) flex: 1 to take remaining space
            // 2) minHeight: 0 so the box can shrink to let content scroll
            flex: 1,
            minHeight: 0,
            overflowY: "auto",
            // Gap or margin if needed
          }}
        >
          {/* "Deepsense Summary" heading */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <AutoAwesomeOutlined
              sx={{
                fill: "#13294B",
                verticalAlign: "sub",
                marginRight: "0.5em",
              }}
            />
            <Typography variant="h6" color={"#13294B"} display="inline-block">
              Deepsense Summary
            </Typography>
          </Box>

          {/* Card that wraps the summary text */}
          <Card sx={{ mb: 2 }}>
            <Box sx={{ padding: "1rem" }}>
              {isStudySummaryLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <CircularProgress />
                </Box>
              ) : (
                formatResponseText(studySummaryResponse?.summary ?? "")
              )}
            </Box>
          </Card>
        </Box>
      </Box>

      {/* RIGHT COLUMN */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: "2rem",
          // If you don't want the right side to scroll, keep no overflow here.
          // If you DO want it scrollable, add "overflowY: 'auto'" and "flex: 1" + "minHeight: 0".
        }}
      >
        <InterviewComponent {...study} fromStudySummary={true} />
      </Box>
    </Box>
  );
}
