import axios from "axios";
import {
  ActivityType,
  ChatRequest,
  ChatResponse,
  QueryEnhancerResponse,
  ThreadFeedback,
} from "../components/Deepchat/models";
import { UserState } from "../components/Authorization/AuthorizationState";
import { buildHeaders } from "./utils";
import { WebSocketChatResponse } from "../contexts/useDeepchat";

const API_URI = import.meta.env.VITE_APP_API_URI;

export const getToken = async (user: UserState) => {
  const response = await axios.get<AuthTokenResponse>(`${API_URI}/chat/token`, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const postQuestion = async (request: ChatRequest, user: UserState) => {
  // Example request: {
  //   "search_request": {
  //     "base_filters": {
  //       "data_source": "VOC Digital Commercial"
  //     },
  //     "dates": {
  //       "start": null,
  //       "end": null
  //     }
  //   },
  //   "user_id": "test_user_01",
  //   "user_chat_session": null,
  //   "query": "What are our customers saying about Actemra?"
  // }

  const response = await axios.post<ChatResponse>(`${API_URI}/chat`, request, {
    headers: buildHeaders(user),
  });

  return response.data;
};

// NOTE: These are not in use yet
export const getHistory = async (user: UserState, activity_type?: ActivityType) => {
  const response = await axios.get<ChatResponse[][]>(
    `${API_URI}/chat/history${activity_type ? `/${activity_type}` : ""}`,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getSession = async (sessionTimeStampString: string, user: UserState) => {
  const response = await axios.get<ChatResponse[]>(`${API_URI}/chat/history/${sessionTimeStampString}`, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const postSessionFeedback = async (feedback: ThreadFeedback, user: UserState) => {
  const response = await axios.put(`${API_URI}/chat/feedback`, feedback, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const getEnhanceQuestion = async (question: string, user: UserState) => {
  const response = await axios.get<QueryEnhancerResponse>(`${API_URI}/chat/query-enhancer`, {
    headers: buildHeaders(user),
    params: { starting_query: question },
  });

  return response.data;
};

export const getQuerySalesResponse = async (question: string, user: UserState) => {
  const response = await axios.post<WebSocketChatResponse<unknown>[]>(
    `${API_URI}/chat/structured-data`,
    { user_question: question },
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};
export interface AuthTokenResponse {
  auth_code: string;
}
