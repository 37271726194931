import { Box } from "@mui/material";
import { useCustomTabs } from "../api/customTabs";
import { TOP_BAR_HEIGHT } from "./AppLayout";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function CustomDashboardLanding() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { customTabs, isLoading } = useCustomTabs();

  const action = params.get("action");

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setTimeout(() => {
      if (action == "new" && customTabs.length < 3) {
        navigate(`/dashboards/custom/new`, { replace: true });
      } else {
        // Else we already have the max number of tabs
        // Route the user to their first custom dashboard (should we display a message on this page instead?)
        navigate(`/dashboards/custom/0`, { replace: true });
      }
    }, 200);
  }, [customTabs, isLoading]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        marginTop: `${TOP_BAR_HEIGHT}px`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: "5rem",
      }}
    >
      Just a moment while we search for your custom tabs...
      <video id="banner-video" autoPlay muted playsInline loop>
        <source src="path/to/your/video.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
}
