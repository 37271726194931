import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { TOP_BAR_HEIGHT } from "./AppLayout";
import { DeepChatSocketContext } from "../contexts/DeepChatSocketContext";
import { useSalesDataStore } from "../components/SalesData/useSalesDataStore";

export default function SalesDataPage() {
  const { lastJsonMessage, sendJsonMessage, readyState } = useSalesDataStore();

  // const FilterDrawerMemo = useMemo(() => {
  //   return (
  //     <FilterDrawer
  //       topPadding={50}
  //       hideFilterDrawer={hideFilterDrawer}
  //       showApplyFilters={false}
  //       disableFilters={disableFilters}
  //     />
  //   );
  // }, [hideFilterDrawer, disableFilters]);

  const hideFilterDrawer = 1 == 1;

  return (
    <>
      {/* {FilterDrawerMemo} */}
      <Box
        component="main"
        sx={{
          marginLeft: hideFilterDrawer !== false ? 0 : 37.5,
          p: 0,
          flexGrow: 1,
          paddingTop: `${TOP_BAR_HEIGHT}px`,
          height: "100vh",
        }}
      >
        <div style={{ padding: "24px", display: "flex", height: "100%" }}>
          <DeepChatSocketContext.Provider value={{ lastJsonMessage, sendJsonMessage, readyState }}>
            <Outlet />
          </DeepChatSocketContext.Provider>
        </div>
      </Box>
    </>
  );
}
