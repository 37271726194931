import { AutoAwesomeOutlined, DocumentScannerOutlined } from "@mui/icons-material";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { TOP_BAR_HEIGHT } from "../../../pages/AppLayout";
import useMarketResearchStore from "../useMarketResearchStore";
import InterviewTranscript from "./InterviewTranscript";

interface RawTranscriptItem {
  speaker: string;
  text: string;
}

export default function ViewTranscript() {
  const { getStudy, studyId, transcripts, isLoadingTranscript } = useMarketResearchStore();
  const study = studyId ? getStudy(studyId) : undefined;

  let jsonFormattingError = false;
  let transcript: RawTranscriptItem[] = [];

  if (transcripts?.transcript) {
    try {
      transcript = JSON.parse(transcripts.transcript) as RawTranscriptItem[];
    } catch (error) {
      console.error(error);
      jsonFormattingError = true;
    }
  }

  let lastSpeaker = "";

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: `${TOP_BAR_HEIGHT}px`,
        padding: "24px",
        overflow: "hidden",
        display: "flex",
        background: "#F5F5F9",
      }}
    >
      {isLoadingTranscript ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "75%" }}>
            <Box sx={{ height: "100%", overflow: "auto" }} className="StudyChat-Scroll">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  flexDirection={"row"}
                >
                  <Typography variant="h5" sx={{ color: "#004677" }}>
                    {study?.study_title}
                  </Typography>
                  <Link to={"/market-research"} style={{ textDecoration: "none", color: "#003087" }}>
                    CHANGE STUDY
                  </Link>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", mt: "2rem", gap: "0.25rem" }}>
                <Box>
                  <AutoAwesomeOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
                  <Typography variant="h6" color={"#13294B"} display="inline-block">
                    Deepsense Summary
                  </Typography>
                </Box>
                <Card sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Box sx={{ padding: "1rem" }}>{formatResponseText(transcripts?.transcript_summary ?? "")}</Box>
                </Card>
                <Box>
                  <DocumentScannerOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
                  <Typography variant="h6" color={"#13294B"} display="inline-block">
                    Transcript
                  </Typography>
                </Box>
                <Card sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Box
                    sx={{
                      padding: "1rem",
                      "& .item": { display: "flex", flexDirection: "row" },
                      "& .item-speaker": { fontWeight: "bold", minWidth: "110px" },
                      "& .item-text": {},
                    }}
                  >
                    {!jsonFormattingError ? (
                      transcript.map((item) => {
                        // Little bit of logic to attempt to visually separate back and forth's between participants
                        const isDifferentSpeaker =
                          lastSpeaker && lastSpeaker != item.speaker && lastSpeaker != "Interviewer";
                        lastSpeaker = item.speaker;

                        return (
                          <Box className="item" sx={isDifferentSpeaker ? { mt: "1em" } : {}}>
                            <Typography className="item-speaker">{item.speaker}: </Typography>
                            <Typography className="item-text">{item.text}</Typography>
                          </Box>
                        );
                      })
                    ) : (
                      <Box>
                        <Typography sx={{ fontWeight: "bold", mb: "1em" }}>
                          Malformed JSON, displaying raw transcript:
                        </Typography>
                        <Typography>{transcripts?.transcript}</Typography>
                      </Box>
                    )}
                  </Box>
                </Card>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              ml: "2em",
              flex: 1,
              display: "flex",
              borderLeft: "1px solid #eee",
              overflow: "hidden",
              flexDirection: "column",
              paddingX: "2rem",
            }}
          >
            <InterviewTranscript />
          </Box>
        </>
      )}
    </Box>
  );
}

export const formatResponseText = (text: string, boldFontSize?: string, otherFontSize?: string) => {
  const response = (text || "").split("\n").flatMap((segment, i) => {
    const isListItem = segment.startsWith("- ");
    const isBlankLine = !segment;

    if (isListItem) {
      // Clean off the "- " and make element a <li>
      return <li key={i}>{segment.replace(/^(- )/, "")}</li>;
    }

    if (segment.endsWith(":")) {
      return (
        <Typography sx={{ fontWeight: "bold", fontSize: boldFontSize ? boldFontSize : "auto" }} key={i}>
          {segment}
        </Typography>
      );
    }

    return (
      <Typography py={isBlankLine ? "0.5em" : 0} key={i} sx={{ fontSize: otherFontSize ? otherFontSize : "auto" }}>
        {segment}
      </Typography>
    );
  });

  return response;
};
