import { Box, Chip, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { WebSocketChatResponse } from "../../contexts/useDeepchat";
import ResponseNode from "./ResponseNode";
import { Interaction } from "./SalesDataState";
import { UserQuestion, CodeResponse, MessageResponse, TabularResponse, FilterResponse } from "./SalesDataTypes";

interface SalesDataInteractionProps {
  interaction: Interaction;
  loading: boolean;
}

export default function SalesDataInteraction({ interaction, loading }: SalesDataInteractionProps) {
  return (
    <div>
      {interaction.messages
        .filter((message) => message.type == "chat.initial")
        .map((response, i) => {
          const message = response as WebSocketChatResponse<UserQuestion>;

          return (
            <ResponseNode
              key={i}
              type={"user_question"}
              header={
                <>
                  <span>Original Question</span>
                  {loading && (
                    <CircularProgress
                      size="small"
                      sx={{
                        gridArea: "1/1",
                        width: "1.25rem",
                        height: "1.25rem",
                        verticalAlign: "sub",
                        marginLeft: "0.5em",
                      }}
                    />
                  )}
                </>
              }
            >
              {message.data.user_question}
            </ResponseNode>
          );
        })}

      <Box>
        {/* {interaction.messages
          .filter(
            (message) =>
              message.type == "chat.structured_data" &&
              (message as WebSocketChatResponse<{ type?: string }>).data?.type == "chips",
          )
          .map((response, i) => {
            const message = response as WebSocketChatResponse<FilterResponse>;

            return message.data.data.map((filter) => <Chip label={filter.condition} />);
          })} */}
      </Box>

      {interaction.messages
        .filter(
          (message) =>
            message.type == "chat.structured_data" &&
            (message as WebSocketChatResponse<{ type?: string; final_output: boolean }>).data.final_output == true,
        )
        .map((response, i) => {
          const message = response as WebSocketChatResponse<MessageResponse>;

          return (
            <ResponseNode key={i} type={message.data.type} header={<span>Answer</span>}>
              <Box sx={{ whiteSpace: "pre-wrap" }}>{message.data.message}</Box>
            </ResponseNode>
          );
        })}

      {interaction.messages.map((rawMessage, i) => {
        if (rawMessage.type == "chat.structured_data") {
          const baseMessage = rawMessage as WebSocketChatResponse<{ type: string; final_output: boolean }>;

          if (baseMessage.data.type == "code") {
            const message = rawMessage as WebSocketChatResponse<CodeResponse>;

            return (
              <ResponseNode
                key={i}
                type={message.data.type}
                language={message.data.language}
                header={<span style={{ textTransform: "capitalize" }}>{message.data.language} Code</span>}
                enableCollapse
              >
                {message.data.code}
              </ResponseNode>
            );
          }

          if (baseMessage.data.type == "tabular") {
            const message = rawMessage as WebSocketChatResponse<TabularResponse>;

            const columns = Object.keys(JSON.parse(message.data.columns));
            const rows = JSON.parse(message.data.data);

            return (
              <ResponseNode key={i} type={baseMessage.data.type} header={<span>Tabular Data</span>} enableCollapse>
                <Box sx={{ height: "420px", width: "100%" }}>
                  <DataGrid
                    columns={columns.map((_) => ({
                      field: _,
                      headerName: _,
                      with: 180,
                    }))}
                    rows={rows.map((_, i) => ({ ..._, id: i }))}
                    disableSelectionOnClick
                  />
                </Box>
              </ResponseNode>
            );
          }

          if (baseMessage.data.type == "message" && baseMessage.data.final_output == false) {
            const message = rawMessage as WebSocketChatResponse<MessageResponse>;

            return (
              <ResponseNode
                key={i}
                type={message.data.type}
                header={<span>Evidence {message.data.type}</span>}
                enableCollapse
              >
                <Box sx={{ whiteSpace: "pre-wrap" }}>{message.data.message}</Box>
              </ResponseNode>
            );
          }

          return undefined;
        }
      })}
    </div>
  );
}
