import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import {
  callGetAllStudies,
  callGetMRQuestions,
  callGetQuestionSummary,
  callMRFilters,
  callMRViewTranscripts,
  callPMRStudySummary,
} from "../../api/dataSources";
import { FilterResponse } from "../../models/filter";
import useUserContext from "../Authorization/useUserContext";
import {
  MarketResearchDrawerStateAtom,
  MarketResearchStateAtom,
  MarketResearchStudy,
  StudyQuestion,
  TMRTranscripts,
  TPMRChat,
  TStudySummary,
} from "./models";

const useMarketResearchStore = () => {
  const user = useUserContext();
  const [state, setState] = useAtom(MarketResearchStateAtom);
  const [drawer, setDrawer] = useAtom(MarketResearchDrawerStateAtom); // I'm not sold on if I like this here
  const navigate = useNavigate();

  const baseFilter = useMemo(() => {
    return {
      base_filters: {
        study: state.studyId ? [state.studyId] : [],
        ...state.filterJson,
      },
    };
  }, [state.studyId, JSON.stringify(state.filterJson)]);

  // Data: All Studies
  const { data: studies, isLoading: isLoadingStudies } = useQuery<MarketResearchStudy[]>({
    queryKey: ["pmr:all_studies"],
    queryFn: () => callGetAllStudies(user),
  });

  // Data: Study Questions by StudyID
  const { data: studyQuestions, isLoading: isLoadingQuestions } = useQuery<StudyQuestion[]>({
    queryKey: ["pmr:study_questions", state.studyId, JSON.stringify(baseFilter)],
    queryFn: () => callGetMRQuestions(user, state.studyId ?? "", baseFilter),
    enabled: !!state.studyId,
  });

  const { data: facets, isLoading: isLoadingFacets } = useQuery<FilterResponse>({
    queryKey: ["pmr:facets", state.studyId],
    queryFn: () =>
      callMRFilters(user, {
        base_filters: {
          study: [state.studyId],
        },
      }),
    enabled: !!state.studyId,
  });

  const { data: transcripts, isLoading: isLoadingTranscript } = useQuery<TMRTranscripts>({
    queryKey: ["pmr:transcripts", state.studyId, state.respondentId],
    queryFn: () => callMRViewTranscripts(user, state.studyId ?? "", state.respondentId ?? ""),
    enabled: !!state.studyId && !!state.respondentId,
    retry: (failureCount, error) => {
      // Retry only if failure count is less than 2 and error is not a 404
      if (failureCount >= 2) return false;
      return true;
    },
  });

  const askQuestion = (sendJsonMessage: SendJsonMessage, question: string, study_name: string) => {
    sendJsonMessage({
      action: "streamChat",
      service_requested: "deepchat",
      user_request: {
        query: question,
        search_request: {
          base_filters: { study_name: study_name, ...state.filterJson },
        },
      },
    });
  };

  const { data: studySummaryResponse, isLoading: isStudySummaryLoading } = useQuery<TStudySummary>({
    queryKey: ["pmr:study_summary", state.studyId],
    queryFn: () => callPMRStudySummary(user, state.studyId ?? ""),
    enabled: !!state.studyId && !!state.showStudySummary,
  });

  const { data: qaChatResponse, isLoading: isQAChatLoading } = useQuery<TPMRChat>({
    queryKey: ["pmr:study_summary", state.questionId],
    queryFn: () => callGetQuestionSummary(user, state.questionId ?? ""),
    enabled: !!state?.questionId,
  });

  const setFilter = (filterName: string, value: string, isChecked: boolean) => {
    setState((prev) => {
      const currentValues = prev.filterJson[filterName] || [];

      const updatedValues = isChecked
        ? [...currentValues, value] // Add the value if checked
        : currentValues.filter((v) => v !== value); // Remove the value if unchecked

      const updatedFilterJson = { ...prev.filterJson };

      if (updatedValues.length > 0) {
        // If the array has values, update the key
        updatedFilterJson[filterName] = updatedValues;
      } else {
        // If the array is empty, remove the key
        delete updatedFilterJson[filterName];
      }

      return {
        ...prev,
        filterJson: updatedFilterJson, // Ensure a new reference
      };
    });
  };

  // Process the data to extract the required arrays
  const { respondentIdsArray, facetsArray } = useMemo(() => {
    if (!facets || !facets.common_filters) {
      return { respondentIds: [], otherFilterValues: [] };
    }

    const respondentIdFilter = facets.common_filters.find((filter) => filter.column_name === "respondent_id");
    const respondentIdsArray = respondentIdFilter ? respondentIdFilter.filter_values : [];
    const facetsArray = facets.common_filters
      .filter((filter) => filter.column_name !== "respondent_id")
      .map((filter) => ({
        filter_name: filter.filter_name,
        column_name: filter.column_name,
        filter_values: filter.filter_values,
      }));
    return { respondentIdsArray, facetsArray };
  }, [facets]);

  const updateRespondentFilterJson = (uncheckedValue: string) => {
    setState((prevState) => {
      // Clone the existing filterJson
      const updatedFilterJson = { ...prevState.filterJson };
      // Ensure base_filters.respondent_id is initialized as a string array or use the default
      const currentRespondentIds: string[] = updatedFilterJson.respondent_id ?? respondentIdsArray ?? [];
      let updatedRespondentIds: string | any[] | undefined;
      if (currentRespondentIds.includes(uncheckedValue)) {
        // Remove the unchecked value from respondent_id
        updatedRespondentIds = currentRespondentIds.filter((id: string) => id !== uncheckedValue);
      } else {
        // Add the unchecked value to respondent_id
        updatedRespondentIds = [...currentRespondentIds, uncheckedValue];
      }

      // Update respondent_id in base_filters
      if (updatedRespondentIds.length > 0) {
        updatedFilterJson.respondent_id = updatedRespondentIds;
      } else {
        // Remove respondent_id key if no values remain
        updatedFilterJson.respondent_id = [];
      }

      return {
        ...prevState,
        filterJson: updatedFilterJson, // Update filterJson in the state
      };
    });
  };

  // Assumes that the ReactQuery for "pmr:all_studies" is loaded
  const getStudy = (study_key: string) => {
    return studies?.find((study) => study.study_key == study_key);
  };

  const editQuestion = (question: string) => {
    setState((_) => ({ ..._, question }));
    navigate(`/market-research/study/${state.studyId}`);
  };

  // Clear will retain the selected studyId
  const clearChatSession = () => {
    setState((_state) => ({
      ..._state,
      question: "",
      isLoading: false,
      messages: [],
    }));
  };

  // Reset will clear everything and reset back to default state
  const resetState = () => {
    setState({
      isLoading: false,
      socketStatusMessage: "",
      messages: [],
      question: "",
      filterJson: {},
      respondentId: "",
    });
  };

  const toggleFilterDrawer = (open?: boolean) => {
    if (open === undefined) {
      setDrawer((_) => ({ ..._, open: !_.open }));
    } else {
      setDrawer((_) => ({ ..._, open }));
    }
  };

  const toggleLoadingChat = (isLoading?: boolean) => {
    if (isLoading === undefined) {
      setState((_) => ({ ..._, isLoading: !_.isLoading }));
    } else {
      setState((_) => ({ ..._, isLoading }));
    }
  };

  return {
    // -- Store Methods
    askQuestion,
    toggleLoadingChat,
    isLoadingChat: state.isLoading,
    socketStatusMessage: state.socketStatusMessage,
    getStudy,
    editQuestion,
    clearChatSession,
    resetState,

    // -- Simple State Interactions
    question: state.question,
    setQuestionResponse: (studyResponse?: StudyQuestion) =>
      setState((_) => ({ ..._, questionResponse: studyResponse })),
    setQuestion: (question: string) => setState((_) => ({ ..._, question })),
    setQuestionId: (questionId: string) => setState((_) => ({ ..._, questionId })),
    qaChatResponse,
    isQAChatLoading,
    questionResponse: state.questionResponse,

    studyId: state.studyId,
    setStudyId: (studyId?: string) => setState((_) => ({ ..._, studyId })),

    respondentId: state.respondentId,
    setRespondentId: (respondentId?: string) => setState((_) => ({ ..._, respondentId })),

    messages: state.messages,
    evidence: state.evicence,

    // -- ReactQuery Managed Variables
    studies,
    isLoadingStudies,

    studyQuestions,
    isLoadingQuestions,

    transcripts,
    isLoadingTranscript,

    toggleStudySummary: () => setState((_) => ({ ..._, showStudySummary: !_.showStudySummary })),
    showStudySummary: state.showStudySummary,
    isStudySummaryLoading,
    studySummaryResponse,

    respondentIdsArray,
    facetsArray,
    facets,
    isLoadingFacets,
    baseFilter,
    setFilter,
    updateRespondentFilterJson,

    // -- Drawer State + Methods
    drawer,
    toggleFilterDrawer,

    navigate,
  };
};

export default useMarketResearchStore;
