import { Box } from "@mui/material";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { AutoSizer } from "react-virtualized";
import SalesDataSessionOutline from "./SalesDataSessionOutline";
import { SalesDataStateAtom } from "./SalesDataState";
import { useSalesDataStore } from "./useSalesDataStore";
import SalesDataInteraction from "./SalesDataInteraction";

export default function SalesDataSession() {
  const navigate = useNavigate();
  const SalesData = useSalesDataStore();
  const [state] = useAtom(SalesDataStateAtom);

  const startNewThread = () => {
    SalesData.clearSession();
    SalesData.setLoading(false);
    navigate(`/deepchat/?salesdata=1`);
  };

  return (
    <>
      {/* Main body content */}
      <Box flex={1} minHeight="100%" maxWidth="48rem" marginX="auto">
        <Box sx={{ "& pre": { whiteSpace: "pre-wrap" } }}>
          {state.interactions.map((interaction, i) => {
            return <SalesDataInteraction interaction={interaction} loading={state.loading} key={i} />;
          })}
        </Box>

        {/* Followup question container */}
        <Box sx={{ height: "84px" }} className="followup-input-base">
          <AutoSizer disableHeight>
            {({ width }) => (
              <Box
                sx={{
                  position: "fixed",
                  width: width,
                  bottom: 0,
                  backgroundImage: "linear-gradient(to bottom, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 25%)",
                  py: "2em",
                }}
              >
                {/* QuestionInput */}
              </Box>
            )}
          </AutoSizer>
        </Box>
      </Box>

      {/* Current Thread Fixed Sidebar  */}
      <>
        <Box sx={{ width: "24vw", minWidth: "360px" }}></Box>
        <Box
          sx={{
            width: "24vw",
            minWidth: "360px",
            ml: "2em",
            pl: "2em",
            borderLeft: "1px solid #eee",
            position: "fixed",
            right: 0,
          }}
        >
          <Box sx={{ display: "flex", gap: "1em", flexDirection: "column", height: "calc(100vh - 74px - 2em)" }}>
            <SalesDataSessionOutline interactions={state.interactions} onClickNewThread={() => startNewThread()} />
          </Box>
        </Box>
      </>
    </>
  );
}
