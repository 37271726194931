import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function DeepchatNavigationTabs() {
  const tabs = [
    {
      route: "/deepchat",
      label: "Ask a Question",
    },
    {
      route: "/deepchat/summarize",
      label: "Summarize a Document",
    },
  ];
  const navigate = useNavigate();

  // Default to the first tab if the URL does not exactly match any of the routes in the "tabs" array
  const uriTabIndex = Math.max(
    tabs.findIndex((t) => t.route === location.pathname),
    0,
  );
  const [value, setValue] = React.useState(uriTabIndex);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ pb: "1em", width: "100%", "& .MuiTabs-root": { border: "none" } }}>
      <Tabs value={value} onChange={handleChange} centered>
        {tabs.map((_tab, index) => {
          return <Tab key={index} value={index} label={_tab.label} onClick={() => navigate(_tab.route)} />;
        })}
      </Tabs>
    </Box>
  );
}
